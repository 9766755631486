.label__position {
    top: 1rem;
    left: 1rem;
}

.icon__position {
    right: 0;
    top: 0;
}

.input::-ms-clear {
    display: none;
}

ul {
    outline: none;
}
