@import url('https://fonts.googleapis.com/css2?family=Oxygen:ital,wght@0,400;0,700;1,400&display=swap');
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "~sass-rem/rem";

html {
    font-size: 16px;
    line-height: 1.6;
}

body {
    font-family: "Oxygen";
    @apply subpixel-antialiased;
    @apply bg-background;
    @apply text-gray-900;
}

a {
    @apply font-extrabold;
    @apply text-primary-500;
    @apply underline;
}

a:hover {
    @apply text-primary-700;
}

a:focus {
    @apply text-primary-500;
    outline-offset: 1px;
    outline-color: #1763D3;
    outline-style: solid;
    outline-width: 1px;
    background: #FFFFFF;
}

a:active {
    @apply text-primary-800;
}

h1 {
    @apply text-black;
    font-size: rem(20px);
}

h2 {
    @apply text-gray-900;
    font-size: rem(20px);
}

h3 {
    @apply text-gray-900;
    font-size: rem(18px);
}

input:hover {
    background-color: #FFFFFF;
}
input:focus {
    border: 1px solid #1763D3;
    outline: none;
}

@media (min-width: 768px) {
    h1 {
        font-size: rem(28px);
    }
    
    h2 {
        font-size: rem(28px);
    }
    
    h3 {
        font-size: rem(20px);
    }
}

::-webkit-scrollbar {
    display: none;
}